document.addEventListener('turbolinks:load', () => {
  var text = ['nowoczesna', 'dochodowa', 'wydajna', 'ułożona', 'łatwa', 'szybka', 'bezproblemowa', 'uporządkowana', 'bezstresowa', 'przejrzysta', 'opłacalna'];
  var counter = 0;
  var elem = $('.adjective');
  setInterval(change, 4000);
  function change() {
    elem.fadeOut(1000, function(){
      elem.html(text[counter]);
      counter++;
      if(counter >= text.length) { counter = 0; }
      elem.fadeIn(1000);
    });
  }
});
